import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { BladeGatsbyCDAContainer } from '../containers/BladeContainer'
import Layout from '../components/Layout'
import config from '../../config'
import { IntersectionProvider } from '@common/hooks/useIntersection'

const Page = ({ data, pageContext, location }) => {
  let { blades } = data?.page?.nodes?.[0]

  return (
    <IntersectionProvider>
      <Layout {...pageContext} pageSEO config={config} location={location}>
        <BladeGatsbyCDAContainer location={location} blades={blades} />
      </Layout>
    </IntersectionProvider>
  )
}

Page.propTypes = {
  data: PropTypes.object,
  pageContext: PropTypes.exact({
    id: PropTypes.string,
    locale: PropTypes.string,
    title: PropTypes.string,
    metaTitle: PropTypes.string,
    metaDescription: PropTypes.string,
    ogImage: PropTypes.string,
  }).isRequired,
}

export default Page

export const query = graphql`
  query Page($id: String!, $locale: String!) {
    page: allContentfulPage(
      filter: { contentful_id: { eq: $id }, node_locale: { eq: $locale } }
    ) {
      nodes {
        ...pageFields
      }
    }
  }
`
